<template>
    <div class="field" :style='{ backgroundImage: `url( ${require(`@/assets/games/mini/miniroulette/field.png`)} )` }'>
        <div class="win" :style=winPosition(resultNumber)>
            <img :src="require(`@/assets/games/mini/miniroulette/win.png`)" alt="New Card" />
        </div>
        <div v-for="(bet, index) in bets " :key="index">
            <MiniRouletteChip :bet="bet" :denom="denom" />
        </div>
    </div>
</template>

<script>
import MiniRouletteChip from './MiniRouletteChip.vue';
export default {
    props: {
        bets: {
            type: Array,
            required: true,
        },
        resultNumber: {
            type: Number,
            required: true
        },
        denom: {
            type: Number,
            required: true
        }
    },
    computed: {
    },
    methods: {
        winPosition(number) {
            let x = 0;
            let y = 0;
            if (number % 2 == 1) {
                y = 60;
            }
            if (number <= 2) {
                x = 0;
            } else if (number <= 4) {
                x = 60;
            } else if (number <= 6) {
                x = 120;
            } else if (number <= 8) {
                x = 180;
            } else if (number <= 10) {
                x = 240;
            } else if (number <= 12) {
                x = 300;
            }
            return {
                left: `${x + 53}px`,
                top: `${y + 3}px`,
            }
        }
    },
    mounted() {
        this.$store.dispatch('workspace/setHeight', 180);
        this.$store.dispatch('workspace/setWidth', 360);
    },
    components: {
        MiniRouletteChip
    }
}
</script>

<style scoped>
.field {
    margin-left: 50px;
    margin-right: 50px;
    width: 360px;
    height: 180px;
}

.win {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 60px;
    height: 60px;
}
</style>
