<template>
    <div class="chip" :style="position"><img :src="require(`@/assets/games/mini/miniroulette/` + color + `.png`)" alt="">
        <h4>{{ betFromCents }}</h4>
    </div>
</template>


<script>
import { fromCents } from "../../../../../helpers/balanceConverter";
export default {
    props: {
        bet: {
            type: Object,
            required: true
        },
        denom: {
            type: Number,
            required: true
        }
    },
    computed: {
        fromCents,
        color() {
            const bet = this.bet.bet / this.denom;
            if (bet < 10) {
                return "grey";
            } else if (bet < 100) {
                return "red";
            } else if (bet < 150) {
                return "blue";
            } else if (bet < 200) {
                return "green";
            } else if (bet < 250) {
                return "dark";
            } else if (bet < 300) {
                return "violet";
            } else {
                return "orange";
            }
        },
        position() {
            const pos = this.bet.pos;
            let x = 0;
            let y = 0;
            if (pos <= 6) {
                x = pos * 60 - 60
            } else if (pos <= 12) {
                y = 60;
                x = (pos - 6) * 60 - 60;
            } else if (pos <= 17) {
                x = (pos - 12) * 60 - 30;
            }
            else if (pos <= 22) {
                y = 60;
                x = (pos - 17) * 60 - 30;
            }
            else if (pos <= 28) {
                y = 30;
                x = (pos - 22) * 60 - 60;
            } else if (pos <= 34) {
                y = 120;
                switch (pos) {
                    case 29:
                        x = 0;
                        break;
                    case 30:
                        x = 300;
                        break;
                    case 31:
                        x = 60;
                        break;
                    case 32:
                        x = 240;
                        break;
                    case 33:
                        x = 120;
                        break;
                    case 34:
                        x = 180;
                        break;
                }
            } else {
                y = 30;
                x = (pos - 34) * 60 - 30;
            }
            return {
                left: `${x + 52}px`,
                top: `${y + 3}px`,
            }
        },
        betFromCents() {
            return fromCents(this.bet.bet);
        },
    },
    methods: {

    }
}
</script>


<style scoped>
.chip {
    position: absolute;
    width: 60px;
    height: 60px;
}

img {
    padding: 15px;
}

h4 {
    position: absolute;
    top: 20px;
    color: white;
    font-weight: 800;
    font-size: 10px;
    width: 60px;
    text-align: center;
}
</style>